import React from "react"
import { Amplify, Auth } from "aws-amplify"
import { Authenticator, useTheme, View, useAuthenticator, AmplifyProvider, CheckboxField  } from '@aws-amplify/ui-react'

//import './styles/index.css';
import App from './App'


//import './styles/globals.css'

import '@aws-amplify/ui-react/styles.css'
import awsconfig from './aws-exports'

Amplify.configure(awsconfig)

const Authentication: React.FC = () => {
  const AuthenticatorComponent = {
    Header() {
      return <h2 className="signin-title">物流委託概算シミュレーター</h2>
    },
    SignUp: {
      FormFields() {
        const { validationErrors } = useAuthenticator();

        return (
          <>
            {/* Re-use default `Authenticator.SignUp.FormFields` */}
            <Authenticator.SignUp.FormFields />

            {/* Append & require Terms & Conditions field to sign up  */}
            <div className="agreement">
              <p className="title">個人情報の取り扱いについて</p>
              <p className="sub-title">下記にご同意いただける場合は、「同意する」ボタンをクリックして、「お問い合わせフォーム」よりお問い合わせください。</p>
              <div className="description">
                <ul>
                  <li>■ご入力いただく個人情報は、お問い合わせの回答を利用目的として使用し、第三者に提供することはなく、又、取り扱いの委託もございません。</li>
                  <li>■当社ウェブサイトにおいてお客さまに個人情報を提供していただくページには、通信途上における第三者の盗聴等を防止する目的で、SSL（Secure Sockets Layer）による暗号化技術を使用いたします。ただし、お客様のコンピューター環境により、これらの技術の利用をご利用いただけない場合もございます。</li>
                  <li>■本サイトではクッキー（Cookie）を使用しております。クッキーとはお客様が本サイトに訪問したときに、カスタマイズ機能等の利用により、サイトを便利にお使い頂く事を目的としております。</li>
                  <li>■誤入力内容に不備が有る場合は、正確にお答えできない場合がございますので、ご了承ください。</li>
                  <li>■個人情報の取り扱いに関するお問い合わせ・苦情等は下記までお電話にてお問い合わせください。</li>
                </ul>
                <p>大塚倉庫株式会社　送付人事部</p>
                <p>東京都中央区晴海4-7-4　CROSS DOCK HARUMI 2F</p>
                <p>TEL: 03-5843-2010</p>
                <p>受付時間：9:00〜17:30（土・日、祝日、当社休業日は除く）</p>
                <p>個人情報保護管理者：総務人事部長</p>
              </div>
            </div>
            <CheckboxField
              errorMessage={validationErrors.acknowledgement as string}
              hasError={!!validationErrors.acknowledgement}
              name="acknowledgement"
              value="yes"
              label="同意する"
            />
          </>
        );
      },
    },
  }

  const AuthenticatorFormFields = {
    signUp: {
      name: {
        label: '氏名',
        labelHidden: false,
        placeholder: '氏名',
        isRequired: true,
      },
      'custom:company': {
        label: '会社名',
        labelHidden: false,
        placeholder: '会社名',
        isRequired: true,
      },
      email: {
        label: 'メールアドレス',
        labelHidden: false,
        placeholder: 'メールアドレス',
        isRequired: true,
      },
      password: {
        label: 'パスワード',
        labelHidden: false,
        placeholder: 'パスワード',
        isRequired: true,
      },
      confirm_password: {
        label: 'パスワード（確認用）',
        labelHidden: false,
        placeholder: 'パスワードをもう一度入力してください',
        isRequired: true,
      },
    },
  }
  return (
    <Authenticator components={AuthenticatorComponent} formFields={AuthenticatorFormFields} hideSignUp={false}
    services={{
      async validateCustomSignUp(formData) {
        if (!formData.acknowledgement) {
          return {
            acknowledgement: '',
          };
        }
      },
    }}
    >
      {({ signOut, user }) => (
        <div>
          <App user={user} onClick={signOut} />
        </div>
      )}
    </Authenticator>
  );
}

export default Authentication