import React from 'react';
import { createRoot } from 'react-dom/client';

import './styles/globals.scss'
import './styles/index.scss';

import Authentication from './Authentication';

import reportWebVitals from './reportWebVitals';

const container: any = document.getElementById('root');
const root = createRoot(container);

root.render(
  //<React.StrictMode>
  <>
    <Authentication />
  </>
  //</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
