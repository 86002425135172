import React, { useRef, useState, createContext, useContext, useEffect } from "react"
import Amplify, { API } from "aws-amplify"
import { saveAs } from "file-saver"

import Template from '../../components/templates/Estimate'
import awsconfig from '../../aws-exports'
import { IEstimateManage, IEstimateManageResponse } from '../../interfaces'
import { UserContext } from '../../App'
//import { blob } from "stream/consumers"

Amplify.configure(awsconfig)

export const CostsContext = createContext<IEstimateManageResponse[]>([])
export const PdfDownloadContext = createContext<any>(null)

const Index = () => {
  console.log('pages/Estimate/index : 見積りメインページ（ビジネスロジック）')
  // estimate_manages databases component.
  const [ dataEstimateManage, setDataEstimateManage ] = useState<any>()
  const user = useContext<any>(UserContext)

  useEffect(() => {
    (async () => {
      console.log('GET DATA -- API: owhEstimate/items - データ取得API')
      const api = 'owhEstimate';
      const method = '/items';
      const myInit = {
        headers: {},
        response: true,
        queryStringParameters: {
        }
      }
      const results = await API.post(api, method, myInit)
      setDataEstimateManage(results.data)
    })()
  }, [])

  const pdfDownload = async (inputData:any) => {
    console.log('GET DATA -- API: owhEstimate/pdf - PDF出力API')
    console.log({...user, ...inputData})
    const api = 'owhEstimate';
    const method = '/pdf';
    const myInit = {
      headers: {},
      response: true,
      queryStringParameters: {...user, ...inputData}
    }
    const results = await API.post(api, method, myInit)
    const url:any = "data:application/pdf;base64," + results.data
    saveAs(url, 'estimate.pdf')
  }
  

    return (
        <>
          <CostsContext.Provider value={dataEstimateManage}>
              <PdfDownloadContext.Provider value={pdfDownload}>
                {dataEstimateManage &&
                <Template/>
                }
              </PdfDownloadContext.Provider>
          </CostsContext.Provider>
        </>
    );
}

export default Index
