import React, { useRef, useState, useImperativeHandle, useContext } from "react"

import './image.css'

import { ModalPortal } from '../../../modal'
import Modal, { ChildHandles } from './DetailModal'
import { CostsDescriptionContext } from '../../../components/organisms/Estimate'

const Footer = () => {
    console.log('components/organisms/Footer/Footer : フッター')

    const costsDescription = useContext<any>(CostsDescriptionContext)

      const childRef = useRef<ChildHandles>(null);

    const onClickViewModal = () => {
        childRef.current?.onModal()
    }

    //const [ costTotalPrice, setCostTotalPrice ] = useState<String>('0')

    return (
        <>
        <div className="footer">
             <div className="action-footer">
            <p className="footer-value"><span>¥</span><span className="_total-value">{ costsDescription.totalPrice.toLocaleString() }</span>（概算価格）</p>
            <p className="footer-button" onClick={onClickViewModal} >詳細</p>
            </div>
        </div>
        <ModalPortal>
            <Modal ref={childRef} />
        </ModalPortal>
        </>
    )
}

export default Footer