import React from 'react';
import './Input.scss';

import { Input } from '../../../atoms/Forms/Input'

interface InputCostsFormProps {
  title?: string
  /**
   * Is this the principal call to action on the page?
   */
  placeholder?: string
  /**
   * Optional click handler
   */
  name?: string

  value?: string

  detail?: string

  description?: string

  unit?: string

  disabled?: boolean

  type?: 'text' | 'password' | 'number' | 'file'

  onChange: ((event: React.ChangeEvent<HTMLInputElement>) => void | undefined)
}

/**
 * Primary UI component for user interaction
 */
export const InputCostsForm = ({
  title,
  placeholder,
  type = 'text',
  name,
  value,
  detail,
  description,
  unit,
  disabled = false,
  onChange,
  ...props
}: InputCostsFormProps) => {

  return (
    <>
        <div className="option-item">
            <h2 className="itemName">{ title }</h2>
            <div className="input-wapper">
                <div className="input-form">
                <Input
                    placeholder={placeholder}
                    name={name}
                    value={value}
                    disabled={disabled}
                    type={type}
                    onChange={onChange}
                />
                </div>
                <div><p className="inputUnit">{ unit }</p></div>
            </div>
            <div className="itemDetail">
                <p>{ detail }</p>
            </div>
            <div className="itemNote">
            <p>{ description }</p>
             </div>
      </div>
    </>
  );
}