import React, { useState, useEffect, forwardRef, useImperativeHandle, useContext } from "react"

import { PdfDownloadContext } from '../../../pages/Estimate'
import { CostsDescriptionContext } from '../../../components/organisms/Estimate'

import './image.css'

export interface ChildHandles {
  onModal(): void;
}

const DetailModal = forwardRef<ChildHandles>((props, ref) => {
  console.log('components/organisms/Footer/DetailModal : 詳細モーダルコンポーネント')

      const pdfDownloadContext = useContext<any>(PdfDownloadContext)
      const costsDescription = useContext<any>(CostsDescriptionContext)
      const [ openModal, setOnModal ] = useState<string>('')
      useImperativeHandle(ref, () => ({
        onModal() {
          setOnModal('-available')
        }
      }))
      const closeModal = () => {
        setOnModal('')
      }
      
    const pdfDownload = () => {
      pdfDownloadContext(costsDescription)
    }

    //useEffect(() => {
    //    console.log('estimate')
    //},[])

    return (
        <>
        <div className={`modal-background ${openModal}`}>
<div className="modal">
  <div className="modal-close" onClick={closeModal}></div>
  <p className="modal-head">詳細</p>
  <div className="modal-main">
    <p className="modal-mainTitle">概算価格</p>
    <p className="modal-value"><span>¥</span><span className="_total-value">{ costsDescription.totalPrice.toLocaleString() }</span>（税別）</p>
    <p className="modal-button" onClick={pdfDownload}>PDF</p>
  </div>
  <div className="modal-detail">
    <p className="datail-head">内訳</p>
    <div className="listGroup">
      <p className="list-title">保管料/一般品</p>
      <p className="list-unit"><span>{ costsDescription.generalCount?.toLocaleString() || '0' }</span>坪</p>
      <p className="list-value _area-value">¥<span>{ costsDescription.calc_general?.toLocaleString() }</span></p>
    </div>
    <div className="listGroup">
      <p className="list-title">保管料/危険物</p>
      <p className="list-unit"><span>{ costsDescription.dangerCount?.toLocaleString() || '0' }</span>坪</p>
      <p className="list-value _area-value">¥<span>{ costsDescription.calc_danger?.toLocaleString() }</span></p>
    </div>
    <div className="listGroup">
      <p className="list-title">保管料/特薬品</p>
      <p className="list-unit"><span>{ costsDescription.specialCount?.toLocaleString() || '0' }</span>坪</p>
      <p className="list-value _area-value">¥<span>{ costsDescription.calc_special?.toLocaleString() }</span></p>
    </div>
    <div className="listGroup">
      <p className="list-title">保管料/保冷庫</p>
      <p className="list-unit"><span>{ costsDescription.coldCount?.toLocaleString() || '0' }</span>坪</p>
      <p className="list-value _area-value">¥<span>{ costsDescription.calc_cold?.toLocaleString() }</span></p>
    </div>
    <div className="listGroup">
      <p className="list-title">保管料/冷凍庫</p>
      <p className="list-unit"><span>{ costsDescription.freezerCount?.toLocaleString() || '0' }</span>坪</p>
      <p className="list-value _area-value">¥<span>{ costsDescription.calc_freezer }</span></p>
    </div>
    <div className="listGroup">
      <p className="list-title">管理薬剤師費用</p>
      <p className="list-unit"><span>{ costsDescription.pharmacistCount?.toLocaleString() || '0' }</span>人</p>
      <p className="list-value _in-value">¥<span>{ costsDescription.calc_pharmacist?.toLocaleString() || '0' }</span></p>
    </div>
    <div className="listGroup">
      <p className="list-title">ネステナレンタル費用</p>
      <p className="list-unit"><span>{ costsDescription.nestainerCount?.toLocaleString() || '0' }</span>基</p>
      <p className="list-value _out-value">¥<span>{ costsDescription.calc_nestainer?.toLocaleString() }</span></p>
    </div>
    <div className="listGroup">
      <p className="list-title">空調設備利用料</p>
      <p className="list-unit"><span>{ costsDescription.acCount?.toLocaleString() || '0' }</span>坪</p>
      <p className="list-value _outline-value">¥<span>{ costsDescription.calc_ac?.toLocaleString() }</span></p>
    </div>
    <div className="listGroup">
      <p className="list-title">防虫防鼠管理料</p>
      <p className="list-unit"><span>{ costsDescription.insectCount?.toLocaleString() || '0' }</span>坪</p>
      <p className="list-value _delivery-value">¥<span>{ costsDescription.calc_insect?.toLocaleString() }</span></p>
    </div>
    <div className="listGroup">
      <p className="list-title">保険料</p>
      <p className="list-unit"><span>{ costsDescription.feeCount?.toLocaleString() || '0' }</span>ケース</p>
      <p className="list-value _delivery-value">¥<span>{ costsDescription.calc_fee?.toLocaleString() }</span></p>
    </div>
    <div className="listGroup">
      <p className="list-title">セキュリティ管理料</p>
      <p className="list-unit"><span>{ costsDescription.securityCount?.toLocaleString() || '0' }</span>坪</p>
      <p className="list-value _delivery-value">¥<span>{ costsDescription.calc_security?.toLocaleString() }</span></p>
    </div>
    <div className="listGroup">
      <p className="list-title">入庫料</p>
      <p className="list-unit"><span>{ costsDescription.receiptCount?.toLocaleString() || '0' }</span>ケース</p>
      <p className="list-value _delivery-value">¥<span>{ costsDescription.calc_receipt?.toLocaleString() }</span></p>
    </div>
    <div className="listGroup">
      <p className="list-title">出庫料/ケース</p>
      <p className="list-unit"><span>{ costsDescription.issueCount?.toLocaleString() || '0' }</span>ケース</p>
      <p className="list-value _delivery-value">¥<span>{ costsDescription.calc_issue?.toLocaleString() }</span></p>
    </div>
    <div className="listGroup">
      <p className="list-title">荷札貼付作業料</p>
      <p className="list-unit"><span>{ costsDescription.tagCount?.toLocaleString() || '0' }</span>ケース</p>
      <p className="list-value _delivery-value">¥<span>{ costsDescription.calc_tag?.toLocaleString() }</span></p>
    </div>
    <div className="listGroup">
      <p className="list-title">出庫料/バラ行数</p>
      <p className="list-unit"><span>{ costsDescription.rowCount?.toLocaleString() || '0' }</span>行</p>
      <p className="list-value _delivery-value">¥<span>{ costsDescription.calc_row?.toLocaleString() }</span></p>
    </div>
    <div className="listGroup">
      <p className="list-title">出庫料/バラ本数</p>
      <p className="list-unit"><span>{ costsDescription.honCount?.toLocaleString() || '0' }</span>本</p>
      <p className="list-value _delivery-value">¥<span>{ costsDescription.calc_hon?.toLocaleString() }</span></p>
    </div>
    <div className="listGroup">
      <p className="list-title">配送料</p>
      <p className="list-unit"><span>{ costsDescription.deliveryCount?.toLocaleString() || '0' }</span>ケース</p>
      <p className="list-value _delivery-value">¥<span>{ costsDescription.calc_delivery?.toLocaleString() }</span></p>
    </div>
  </div>
  <p className="modal-note">*詳細な見積もりは営業担当まで</p>
</div>

</div>
        </>
    )
})

export default DetailModal
