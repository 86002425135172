import React, { createContext, useRef } from "react"
import { Route, Routes, BrowserRouter } from "react-router-dom"

import Header from './components/organisms/Header/Header'
import Estimate from './pages/Estimate/index'

//import './styles/globals.css'

import '@aws-amplify/ui-react/styles.css'

// I18nをimportし言語を変更
import { I18n } from "aws-amplify";
const dict = {
  ja: {
    "Forgot your password?": "パスワードを忘れた場合",
    "Reset your password": "パスワードをリセット",
    "Back to Sign In": "サインイン画面に戻る",
    "Send code": "検証コードを送信",
    "Enter your Email": "メールアドレスを入力",
    "Enter your Password": "パスワードを入力",
    "Sign In": "ログイン",
    "Create Account": "新規登録",
    "Pick a file": "実績ファイルを選択",
    "Email": "メールアドレス",
    "Password": "パスワード",
    "Enter your code": "認証コードを入力してください",
    "We Emailed You": "メールアドレスの認証",
    "Confirm": "認証",
    "Resend Code": "認証コードの再送信",
    "Your code is on the way. To log in, enter the code we emailed to": "登録されたメールアドレス",
    "It may take a minute to arrive.": "に認証コードを送信しました。",
  },
};
I18n.putVocabularies(dict);
I18n.setLanguage("ja");

const ADMIN_GROUP_NAME = 'Admins'

export const UserContext = createContext({} as {
  userId?: string
  isAdmin?: boolean
})
interface AuthProps {
  user?: any
  onClick?: () => void
}
const App = ({
  user,
  onClick,
}: AuthProps) => {
  const userId: string = user?.username
  const userName: string = user?.attributes.name
  console.log(user)

  const isAdmin = (user: any): boolean => {
    const groups = user?.signInUserSession?.accessToken?.payload[
      'cognito:groups'
    ] as Array<string>
    return groups?.includes(ADMIN_GROUP_NAME) ?? false
  }
  const showAdmin = useRef(false)
  if (isAdmin(user)===true) {
    showAdmin.current = true
  }

  return (
    <>
      <BrowserRouter>
        <main>
        <UserContext.Provider value={{ userId: userId, isAdmin: showAdmin.current }}>
          <Header onClick={onClick} user={userName} />
          <div className="contents">
            <Routes>
              <Route path='/' element={<Estimate />} />
            </Routes>
          </div>
          </UserContext.Provider>
        </main>
      </BrowserRouter>
    </>
  );
}

export default App