import React, { useRef, useState, createContext, useContext, useEffect, useMemo } from "react"
import Amplify, { API,Storage } from 'aws-amplify'
import { AmplifyS3TextPicker, AmplifyS3ImagePicker } from '@aws-amplify/ui-react/legacy'
import { INPUT_DEFAULT, COSTS_DESCRIPTION, ESTIMATE_MANAGE } from '../../../constants/estimate_manage'
import { Loader } from '@aws-amplify/ui-react';
import * as Scroll from 'react-scroll'
import { InputCostsForm }  from '../../molecules/Forms/Estimate/InputCostsForm'
import Footer from '../../organisms/Footer/Footer'
import { IEstimateManage, IEstimateManageResponse, StringKeyObject } from "../../../interfaces"
import { CostsContext } from '../../../pages/Estimate'
import { ChangePanelImageContext } from '../../templates/Estimate'
import awsconfig from '../../../aws-exports'
import { UserContext } from '../../../App'
Amplify.configure(awsconfig)

export const CostsDescriptionContext = createContext<any>({})

type TItem = {
    title: string
    name: string
    detail: string
    description: string
    value: string
    unit: string
}

const Index = () => {
    console.log('templates/Estimate/index : インプットフォーム')
    const user = useContext<any>(UserContext)

    const [ estimateCosts, setEstimateCosts ] = useState<StringKeyObject>(INPUT_DEFAULT)
    const currentPanel = useRef('PanelA')
    const changePanelImageContext = useContext<any>(ChangePanelImageContext)
    const manage = useContext<IEstimateManageResponse[]>(CostsContext)
    const [ calcType, setCalcType ] = useState<string>('manual')

    const panelARef = useRef<HTMLDivElement>(null)
    const panelBRef = useRef<HTMLDivElement>(null)
    const panelCRef = useRef<HTMLDivElement>(null)

    const isLoader = useRef(false)
    const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist()
        const target = e.target
        const name = target.name
        isLoader.current = true
        uploader()
    }
    const [percentage, setPercentage] = React.useState(0);
    const uploader = () => {
        const clearID = setInterval(() => {
            setPercentage((percentage) => {
              if (percentage <= 100) {
                return percentage + 25;
              }
              isLoader.current = false
              return 0;
            });
          }, 1000)
          return () => clearInterval(clearID);
    }

    const [ key, setKey ] = useState<any>()

    useEffect(() => {
        console.log('use effect')
        const scrollCurrentPanel = (panel: string) => {
            currentPanel.current = panel
            changePanelImageContext(panel)
        }

        const scrollAction = () => {
            const scrollTop = window.scrollY + window.innerHeight / 3
            if ((panelBRef.current!==null && (scrollTop <= panelBRef.current.offsetTop))) {
                if (currentPanel.current !== 'PanelA') {
                    scrollCurrentPanel('PanelA')
                }
            }
            if ((panelBRef.current!==null && (scrollTop > panelBRef.current.offsetTop))
                && (panelCRef.current!==null && (scrollTop <= panelCRef.current.offsetTop))) {
                    if (currentPanel.current !== 'PanelB') {
                        scrollCurrentPanel('PanelB')
                    }
            }
            if (panelCRef.current!==null && (scrollTop > panelCRef.current.offsetTop)) {
                if (currentPanel.current !== 'PanelC') {
                    scrollCurrentPanel('PanelC')
                }
            }
            
          };
        window.addEventListener("scroll", scrollAction, {
            capture: false,
            passive: true,
          })
          scrollAction()
        
          return () => {
            window.removeEventListener("scroll", scrollAction)
          };
    },[changePanelImageContext,currentPanel])

    const handleCalcType = (e: React.ChangeEvent<HTMLInputElement>, type: string, pos: number) => {
        setCalcType(type)
        Scroll.animateScroll.scrollTo(pos)
    }

    const [ selectManage, setSelectManage ] = useState<IEstimateManageResponse|null>(null)
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, item?: IEstimateManageResponse) => {
        e.persist()
        const target = e.target
        const name = target.name

        let updateCosts: any = { ...estimateCosts, [name]: target.value }
        if (item) {
            setSelectManage(item)
        }
        
        if (item || selectManage) {
            const estimateManage = (item) ? item : selectManage

            if (estimateManage) {
                if (calcType==='palette' && name==='input_2') {
                    updateCosts.input_1 = Math.ceil(parseInt(updateCosts.input_2 || 0) / estimateManage.storage_efficiency) || ''
                }
                if (calcType==='case' && name==='input_3') {
                    updateCosts.input_2 = Math.ceil(parseInt(updateCosts.input_3 || 0) / estimateManage.palette) || ''
                    updateCosts.input_1 = Math.ceil(parseInt(updateCosts.input_2 || 0) / estimateManage.storage_efficiency) || ''
                }
                // else if (!parseInt(updateCosts.input_2 || 0)) {
                //    updateCosts.input_2 = (parseInt(updateCosts.input_3 || 0) / estimateManage.palette) || ''
                //}
                if (calcType==='issue' && name==='input_9') {
                    updateCosts.input_3 = Math.ceil((parseInt(updateCosts.input_9 || 0) * estimateManage.inventory_rate) / 12) || ''
                    updateCosts.input_2 = Math.ceil(parseInt(updateCosts.input_3 || 0) / estimateManage.palette) || ''
                    updateCosts.input_1 = Math.ceil(parseInt(updateCosts.input_2 || 0) / estimateManage.storage_efficiency) || ''
                }
                // else if (!parseInt(updateCosts.input_2 || 0) && !parseInt(updateCosts.input_3 || 0)) {
                //    updateCosts.input_3 = ((parseInt(updateCosts.input_9 || 0) * estimateManage.inventory_rate) / 12) || ''
                //    updateCosts.input_2 = (parseInt(updateCosts.input_3 || 0) / estimateManage.palette) || ''
                //}
            }
            if (name==='input_9') {
                updateCosts.input_12 = 2
                updateCosts.input_8 = updateCosts.input_9
            }
            //if (parseInt(updateCosts.input_8 || 0) > 0) {
            //    updateCosts.input_12 = 2
            //}
            calcEstimateCosts(updateCosts, estimateManage)
        }
        setEstimateCosts(updateCosts)  
    }
    const [ costsDescription, setCostsDescription ] = useState<any>(COSTS_DESCRIPTION)

    const calcEstimateCosts = (c: IEstimateManage, m: any) => {

        const input_nestainer: number = (parseInt(c.input_2 || '0') / 3) * 2

        const general: number = (parseInt(m.unit_price) + parseInt(m.unit_price_manage)) * parseInt(c.input_1 || '0') * 12//ok

        // ok
        const danger: number = parseInt(m.unit_price_danger) * parseInt(c.input_4 || '0') * 12
        const special: number = parseInt(m.unit_price_special) * parseInt(c.input_5 || '0') * 12
        const cold: number = parseInt(m.unit_price_cold) * parseInt(c.input_6 || '0') * 12
        const freezer: number = parseInt(m.unit_price_freezer) * parseInt(c.input_7 || '0') * 12

        const pharmacist: number = (parseInt(m.pharmacist) * parseInt(c.input_12 || '0')) * 12//ok

        const ac: number = parseInt(m.unit_price_ac) * parseInt(c.input_1 || '0') * 12
        const nestainer: number = parseInt(m.nestainer) * input_nestainer * 12
        const insect: number = parseInt(m.unit_price_insect) * parseInt(c.input_1 || '0') * 12//ok
        const fee: number = parseInt(m.insurance_fee_logi) * parseInt(c.input_3 || '0')//ok
        const security: number = parseInt(m.unit_price_security) * parseInt(c.input_1 || '0') * 12//ok

        const receipt: number = parseInt(m.receipt) * parseInt(c.input_8 || '0')
        const issue: number = parseInt(m.issue) * parseInt(c.input_9 || '0')

        const tag: number = parseInt(m.tag) * parseInt(c.input_9 || '0')
        const row: number = parseInt(m.issue_row) * parseInt(c.input_10 || '0')
        const hon: number = parseInt(m.issue_hon) * parseInt(c.input_11 || '0')
        const delivery: number = parseInt(m.delivery) * parseInt(c.input_9 || '0')
        const total: number = general + danger + special + cold + freezer + pharmacist + ac + nestainer + insect + fee + security + receipt + issue + tag + hon + row + delivery

        setCostsDescription({
            totalPrice: total,
            calc: (calcType==='palette') ? 1 : (calcType==='case') ? 2 : (calcType==='issue') ? 3 : 0,
            tax: 10,
            calc_general: general || 0,
            generalCount: parseInt(c.input_1 || '0'),
            palletCount: parseInt(c.input_2 || '0'),
            caseCount: parseInt(c.input_3 || '0'),
            calc_danger: danger || 0,
            dangerCount: parseInt(c.input_4 || '0'),
            calc_special: special || 0,
            specialCount: parseInt(c.input_5 || '0'),
            calc_cold: cold || 0,
            coldCount: parseInt(c.input_6 || '0'),
            calc_freezer: freezer || 0,
            freezerCount: parseInt(c.input_7 || '0'),
            calc_pharmacist: pharmacist || 0,
            pharmacistCount: parseInt(c.input_12 || '0'),
            calc_nestainer: nestainer || 0,
            nestainerCount: Math.ceil(input_nestainer),
            calc_ac: ac || 0,
            acCount: parseInt(c.input_1 || '0'),
            calc_insect: insect || 0,
            insectCount: parseInt(c.input_1 || '0'),
            calc_fee: fee || 0,
            feeCount: parseInt(c.input_3 || '0'),
            calc_security: security || 0,
            securityCount: parseInt(c.input_1 || '0'),
            calc_receipt: receipt || 0,
            receiptCount: parseInt(c.input_8 || '0'),
            calc_issue: issue || 0,
            issueCount: parseInt(c.input_9 || '0'),
            calc_tag: tag || 0,
            tagCount: parseInt(c.input_9 || '0'),
            calc_row: row || 0,
            rowCount: parseInt(c.input_10 || '0'),
            calc_hon: hon || 0,
            honCount: parseInt(c.input_11 || '0'),
            calc_delivery: delivery || 0,
            deliveryCount: parseInt(c.input_9 || '0'),
            ...c,
            ...m,
        })
    }

    return (
        <>
            <div className="option-wapper">
                <div ref={panelARef}>
                    <div className="input-block">
                        <div className="option-head">
                            <p>大塚倉庫へ物流業務を委託する場合の費用を概算見積もりできます。</p>
                        </div>
                        <div className="option-item">
                            <h1 className="serviceName">物流委託概算<br />シミュレーター</h1>
                            <ul className="option-selectName">
                                <li>取り扱い商品</li>
                                <li>坪単価</li>
                            </ul>
                            { manage?.map((item: IEstimateManageResponse, idx: number) => {
                                return (
                                <div key={idx}>
                                    <input id={`item-${idx}`} className="radio-input" type="radio" name="manage_id" value={item.manage_id} onChange={(e) => handleInputChange(e, item) } checked={estimateCosts.manage_id === item.manage_id.toString()}/>
                                    <label className="radio-label" htmlFor={`item-${idx}`}>
                                        <p>{ item.manage_name }</p>
                                        <p>￥{ item.unit_price.toLocaleString() }/坪</p>
                                    </label>
                                </div>
                                )}
                             )}
                        </div>
                    </div>
                </div>
                <div ref={panelBRef}>
                    <div className="input-block">
                        <InputCostsForm
                            title="保管数（面積）"
                            name="input_1"
                            detail=""
                            description=""
                            unit="坪"
                            value={estimateCosts.input_1}
                            onChange={handleInputChange}
                        />
                        <div className="radio">
                            <input id="radio-1" name="radio" type="radio" checked={calcType === 'manual'} onChange={(e) => setCalcType('manual') }/>
                            <label htmlFor="radio-1" className="radio-label-def">手動</label>
                        </div>
                        <div className="radio">
                            <input id="radio-2" name="radio" type="radio" checked={calcType === 'palette'} onChange={(e) => handleCalcType(e, 'palette', 1100)}/>
                            <label htmlFor="radio-2" className="radio-label-def">パレットから計算</label>
                        </div>
                        <div className="radio">
                            <input id="radio-3" name="radio" type="radio" checked={calcType === 'case'} onChange={(e) => handleCalcType(e, 'case', 1400)}/>
                            <label htmlFor="radio-3" className="radio-label-def">ケースから計算</label>
                        </div>
                        <div className="radio">
                            <input id="radio-4" name="radio" type="radio" checked={calcType === 'issue'} onChange={(e) => handleCalcType(e, 'issue', 1800)}/>
                            <label htmlFor="radio-4" className="radio-label-def">出庫ケースから計算</label>
                        </div>
                        
                        <InputCostsForm
                            title="保管数（パレット）"
                            name="input_2"
                            detail=""
                            description=""
                            unit="枚"
                            value={estimateCosts.input_2}
                            onChange={handleInputChange}
                        />
                        <InputCostsForm
                            title="保管数（ケース）"
                            name="input_3"
                            detail=""
                            description=""
                            unit="ケース"
                            value={estimateCosts.input_3}
                            onChange={handleInputChange}
                        />
                        <InputCostsForm
                            title="出庫ケース数/年"
                            name="input_9"
                            detail={'1ケースあたり' + (selectManage?.issue.toLocaleString() || '--') + '円'}
                            description=""
                            unit="ケース"
                            value={estimateCosts.input_9}
                            onChange={handleInputChange}
                        />
                        <InputCostsForm
                            title="危険物保管数（面積）"
                            name="input_4"
                            detail={'1坪あたり' + (selectManage?.unit_price_danger.toLocaleString() || '--') + '円/月'}
                            description=""
                            unit="坪"
                            value={estimateCosts.input_4}
                            onChange={handleInputChange}
                        />
                        <InputCostsForm
                            title="特薬保管数（面積）"
                            name="input_5"
                            detail={'1坪あたり' + (selectManage?.unit_price_special.toLocaleString() || '--') + '円/月'}
                            description=""
                            unit="坪"
                            value={estimateCosts.input_5}
                            onChange={handleInputChange}
                        />
                        <InputCostsForm
                            title="保冷保管数（面積）"
                            name="input_6"
                            detail={'1坪あたり' + (selectManage?.unit_price_cold.toLocaleString() || '--') + '円/月'}
                            description=""
                            unit="坪"
                            value={estimateCosts.input_6}
                            onChange={handleInputChange}
                        />
                        <InputCostsForm
                            title="冷凍保管数（面積）"
                            name="input_7"
                            detail={'1坪あたり' + (selectManage?.unit_price_freezer.toLocaleString() || '--') + '円/月'}
                            description=""
                            unit="坪"
                            value={estimateCosts.input_7}
                            onChange={handleInputChange}
                        />
                        <InputCostsForm
                            title="管理薬剤師雇用人数"
                            name="input_12"
                            detail={'1名あたり' + (selectManage?.pharmacist.toLocaleString() || '--') + '円/月'}
                            description=""
                            unit="人"
                            value={estimateCosts.input_12}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
                <div ref={panelCRef}>
                    <div className="input-block issue">
                        <InputCostsForm
                            title="入庫ケース数/年"
                            name="input_8"
                            detail={'1ケースあたり' + (selectManage?.receipt.toLocaleString() || '--') + '円'}
                            description=""
                            unit="ケース"
                            value={estimateCosts.input_8}
                            onChange={handleInputChange}
                            disabled={false}
                        />
                        <InputCostsForm
                            title="出庫バラ行数/年"
                            name="input_10"
                            detail={'1行あたり' + (selectManage?.issue_row.toLocaleString() || '--') + '円'}
                            description=""
                            unit="行"
                            value={estimateCosts.input_10}
                            onChange={handleInputChange}
                        />
                        <InputCostsForm
                            title="出庫バラ本数/年"
                            name="input_11"
                            detail={'1本あたり' + (selectManage?.issue_hon.toLocaleString() || '--') + '円'}
                            description=""
                            unit="本"
                            value={estimateCosts.input_11}
                            onChange={handleInputChange}
                        />
                        {user.isAdmin===true ?
                        <InputCostsForm
                            title="ファイルアップロード"
                            name="costsFile"
                            type="file"
                            detail={'CSVまたはエクセルファイルのみです。'}
                            description=""
                            unit=""
                            //value={estimateCosts.input_6}
                            onChange={onFileInputChange}
                        />
                        : ''}
                     </div>   
                </div>
            </div>
            <CostsDescriptionContext.Provider value={costsDescription}>
                <Footer/>
            </CostsDescriptionContext.Provider>
            { isLoader.current===true && <div className="loader">
            <Loader variation="linear" percentage={percentage} isDeterminate />
            </div> }
        </>
    )
}

export default Index