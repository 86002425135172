import React from "react"

import '@aws-amplify/ui-react/styles.css'
import '../../../styles/globals.scss'

interface HeaderProps {
  user?: string
  onClick?: () => void
}

const Header = ({
  user,
  onClick,
}: HeaderProps) => {
  return (
    <>
      <div className="header">
        <div className="logo"></div>
        <div className="userList">
          <p className="name">{user}様</p>
          <p className="logout" onClick={onClick}>ログアウト</p>
        </div>
      </div>
    </>
  );
}

export default Header
