import {
    IEstimateManage,
    StringKeyObject,
} from '../interfaces'

/**  マネージスキーマに合わせて編集してください。 **/
export const ESTIMATE_MANAGE = {
    manage_id: '',
    manage_name: '',
    unit_price: 0,
    unit_price_manage: 0,
    palette: 0,
    inventry_rate: '',
    unit_price_danger: 0,
    unit_price_special: 0,
    unit_price_cold: 0,
    unit_price_freezer: 0,
    pharmacist: 0,
    nestainer: 0,
    unit_price_ac: 0,
    storage_efficiency: '',
    unit_price_insect: 0,
    insurance_fee_logi: 0,
    unit_price_security: 0,
    receipt: 0,
    issue: 0,
    tag: 0,
    issue_row: 0,
    issue_hon: 0,
    delivery: 0,
    palette_collection: 0,
    transshipment: 0,
    insurance_fee_ship: 0,
    edit_date: '',
    user_id: 0,
}
export const INPUT_DEFAULT = {
    manage_id: '',
    input_1: '',
    input_2: '',
    input_3: '',
    input_4: '',
    input_5: '',
    input_6: '',
    input_7: '',
    input_8: '',
    input_9: '',
    input_10: '',
    input_11: '',
    input_12: '',
    input_13: '',
    input_14: '',
    input_15: '',
    input_16: '',
}
export const CATEGORY_B = [
    {
        title: '一般倉庫保管坪数',
        name: 'input_1',
        detail: '',
        description: '',
        value: '',
        unit: '坪',
    },
    {
        title: 'パレット数',
        name: 'input_2',
        detail: '',
        description: '',
        value: '',
        unit: '枚',
    },
    {
        title: '在庫数',
        name: 'input_3',
        detail: '',
        description: '',
        value: '',
        unit: 'ケース',
    },
]
export const CATEGORY_C = [
    {
        title: '年間入庫ケース数',
        name: 'input_4',
        detail: '・入庫1ケースあたり15円/年',
        description: '',
        value: '',
        unit: 'ケース',
    },
    {
        title: '年間出庫ケース数',
        name: 'input_5',
        detail: '・出庫1ケースあたり20円/年',
        description: '',
        value: '',
        unit: 'ケース',
    },
    {
        title: '年間出庫バラ行数',
        name: 'input_6',
        detail: '・出庫1バラあたり80円/年',
        description: '',
        value: '',
        unit: '行',
    },
    {
        title: '年間出庫バラ本数',
        name: 'input_7',
        detail: '・出庫1バラあたり80円/年',
        description: 'バラ商品の出庫料ついては、伝票に記載のバラ商品行数に応じて作業費用を頂戴しています。伝票業数を入力するとおよその概算が作成できます。',
        value: '',
        unit: '本',
    },
]

export const COSTS_DESCRIPTION = {
    totalPrice: 0,
    general: 0,
    danger: 0,
    special: 0,
    cold: 0,
    freezer: 0,
    phamacist: 0,
    nestainer: 0,
    ac: 0,
    insect: 0,
    fee: 0,
    security: 0,
    receipt: 0,
    issue: 0,
    tag: 0,
    hon: 0,
    row: 0,
    delivery: 0,
    generalCount: 0,
    dangerCount: 0,
    specialCount: 0,
    coldCount: 0,
    freezerCount: 0,
    phamacistCount: 0,
    nestainerCount: 0,
    acCount: 0,
    insectCount: 0,
    feeCount: 0,
    securityCount: 0,
    receiptCount: 0,
    issueCount: 0,
    tagCount: 0,
    honCount: 0,
    rowCount: 0,
    deliveryCount: 0,
}