import React, { useRef, createContext } from "react"

import './index.scss'
import '../../organisms/Estimate/image.scss'

import { IEstimateManageResponse } from "../../../interfaces"

import EstimateImagePanel from '../../organisms/Estimate/panel'
import EstimateInputForms from '../../organisms/Estimate/index'
import { ChildHandles } from '../../organisms/Estimate/panel'

//export const SelectCostsContext = createContext<IEstimateManageResponse>({} as IEstimateManageResponse)
export const ChangePanelImageContext = createContext<any>(null)

const Index = () => {
    console.log('templates/Estimate/index : 見積りテンプレート')

    const childRef = useRef<ChildHandles>(null);
    
    const ChangePanelImage = (item: string) => {
        if (item) {
            childRef.current?.onChangePanel(item)
        }
    }

    return (
        <>
            <div className="image-wapper">
                <EstimateImagePanel ref={childRef} />
            </div>
            <ChangePanelImageContext.Provider value={ChangePanelImage}>
                <EstimateInputForms/>
            </ChangePanelImageContext.Provider>
            
        </>
    )
}

export default Index